.payment-status {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
}

.payment-status__in-progress {
  display: flex;
  align-items: center;
  flex-direction: column;
}

/* .payment-status__in-progress p {
  color: var(--dark);
  font-size: 48px;
  font-style: normal;
  font-weight: 400;
} */

.payment-status__success {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.payment-status__footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 1000;
  padding: 10px;
}

.payment-status__footer__button {
  width: 100%;
  height: 140px;

  border-radius: 20px;
  border: none;
  background: var(--primary);

  color: #FFF;
  text-align: center;
  font-size: 37.833px;
  font-style: normal;
  font-weight: 700;
}

.payment-status__title {
  color: var(--dark);
  text-align: center;
  font-size: 48px;
  font-style: normal;
  font-weight: 600;
  padding: 10px;
}

.payment-status__sub-title {
  color: var(--dark);
  font-size: 36px;
  font-style: normal;
  font-weight: 400;
  padding: 10px;
}

.payment-status__in-progress__arrows {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 54px;
}