.content-app {
  margin-top: 90px;
  padding: 2rem 10rem 2rem 10rem;
}

.title {
  display: flex;
  margin-bottom: 2rem;
}

.content-app__title {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  padding: 10px 0px;
}


.content-app__title img {
  width: 67px;
  height: 67px;
}

.content-app__title h1 {
  color: var(--dark);
  font-size: 64px;
  font-style: normal;
  font-weight: 700;
}