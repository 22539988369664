:root {
  --primary: #F57423;
  --dark: #4F4545;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  font-size: 62.5%;
  font-family: 'Inter', sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
  font-variation-settings: 'slnt' 0;
}

body {
  font-size: 1.6rem;
}