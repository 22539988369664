.product-list {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.ant-tabs {
  margin-bottom: 30rem;
}

.ant-tabs-tab .ant-tabs-tab-btn {
  color: var(--dark);
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
}
