.footer-cart {
  position: fixed;
  display: flex;
  gap: 40px;
  flex-direction: column;
  align-items: center;
  justify-content: right;
  bottom: 0;
  width: 100%;
  z-index: 1000;
  background: white;
  padding: 10px 70px 90px 70px;
}

.footer-cart__button-pay {
  width: 100%;
  height: 200px;

  border-radius: 20px;
  border: none;
  background: var(--primary);

  color: #FFF;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
}

.footer-cart__button-pay:disabled {
  background: #CECECE;
}