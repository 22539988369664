.home-page {
  position: absolute;
  display: flex;
  flex-direction: column;
  background-color: black;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: space-evenly;
}

.home-page button {
  position: relative;
  border: none;
  border-radius: 20px;
  background: var(--primary);
  width: 510px;
  padding: 62px 30px;
  align-items: center;

  color: white;
  text-align: center;
  font-size: 48px;
  font-style: normal;
  font-weight: 700;

  box-shadow: 0px 0px 100px 0px #F57423;
}

.home-page__greeting {
  display: flex;
  color: white;
  font-size: 48px;
  flex-direction: column;
  align-items: center;font-style: normal;
  font-weight: 700;
}

.version {
  color: white;
  font-size: 12px;
}