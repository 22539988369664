.cart-page {
  margin-top: 90px;
  padding: 2rem 10rem 2rem 10rem;
}

.cart-page__title {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  padding: 10px 0px;
}


.cart-page__title img {
  width: 67px;
  height: 67px;
}

.cart-page__title h1 {
  color: var(--dark);
  font-size: 64px;
  font-style: normal;
  font-weight: 600;
}

.cart-page__sub-title {
  color: var(--dark);
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  padding: 12px 0px;
}

.cart-page__item {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
}

.cart-page__item__product {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
}

.cart-page__item__product img {
  width: 150px;
  height: 150px;
  border-radius: 10px;
  border: 1px solid #CECECE;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.10) inset;
}

.cart-page__item__product__title {
  color: var(--dark);
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
}

.cart-page__item__product__price {
  color: var(--primary);
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
}

.cart-page__item__actions {
  display: inline-block;
  position: relative;
  border-radius: 20px;
  bottom: 20px;
  right: 5px;
  background: var(--primary);
}

.cart-page__item__actions * {
  display: inline;
}

.cart-page__item__actions p {
  margin-left: 8px;
  margin-right: 8px;
  font-size: 14px;
  font-weight: 700;
  color: white;
}

.ant-collapse-header-text, .ant-collapse-content {
  color: var(--dark);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}

.ant-collapse-content ul {
  list-style: none;
}
