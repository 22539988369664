.modal-container {
  display: flex;
  flex-direction: row;
  gap: 20px;
}
.modal-container__image {
  width: 180px;
  height: 180px;
  min-width: 180px;
  border-radius: 10px;
  border: 1px solid #CECECE;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.10) inset;
  overflow: hidden;
}

.modal-container__image img {
  max-width: 100%;
  max-height: 100%;
}

.modal-container__detail {
  width: 100%;
  padding: 10px;
}

.modal-container__detail .product-name {
  color: var(--dark);
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
}

.modal-container__detail .product-description {
  color: var(--dark);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
}

.modal-container__detail .product-price {
  color: var(--primary);
  font-size: 18px;
  padding: 5px 0px;
  font-style: normal;
  font-weight: 500;
}

.modal__button-group {
  display: flex;
  gap: 10px;
  justify-content: right;
}

.modal-container__detail__cart-actions {
  display: flex;
  gap: 10px;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0px;

}

.modal-container__detail__cart-actions p {
  color: var(--dark);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}

.cart-actions-white {
  display: inline-flex;
  align-items: center;
  border-radius: 20px;
  border: 1px solid var(--primary);
  background: white;
}

.cart-actions-white p {
  color: var(--primary);
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
}

.cart-actions-white button {
  border: none;
}
.cart-actions-white button .ant-btn-icon {
  color: var(--primary);
}

.group-header {
  padding: 10px 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.group-header .group-name {
  color: var(--dark);
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
}

.group-header .group-optional {
  color: var(--dark);
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  padding: 10px;
}

.group-header .group-required {
  color: var(--primary);
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  padding: 10px;

  border-radius: 5px;
  background: linear-gradient(0deg, rgba(245, 116, 35, 0.20) 0%, rgba(245, 116, 35, 0.20) 100%);
}

.group-criteria {
  padding: 10px 0px;
}

.group-criteria .group-criteria__text {
  color: var(--dark);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}

.group-criteria .group-criteria__text .group-criteria__text-error {
  color: red;
}

.group-criteria .group-criteria__text .group-criteria__text-success {
  color: green;
}

.group-criteria .group-criteria__text .group-criteria__text-warning {
  color: yellow;
}

.group-options .group-options-checkboxes, .group-options-radios {
  display: flex;
  flex-direction: column;
}

.group-options .group-options-checkboxes .ant-checkbox-group-item, .ant-radio-wrapper {
  padding: 10px 0px;
}